import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

import imgModalSuccess from '../images/body/img-modal-success.png';

function ModalApplySuccess(props) {

  return (
    <Modal {...props} size="sm">
      <Button onClick={props.onHide} className="btn-close">
        <span>X</span>
      </Button>
      <div className="modal-content-success">
        <img src={imgModalSuccess} alt="" />
      </div>
    </Modal>
  );
}

const client_id = process.env.REACT_APP_CLIENT_ID;
const client_secret = process.env.REACT_APP_CLIENT_SECRET;
const refresh_url = process.env.REACT_APP_REFRESH_URL;
const refresh_token = process.env.REACT_APP_REFRESH_TOKEN;

export default function ModalApply(props) {
  console.log("props", props);
  const post_body = `grant_type=refresh_token&client_id=${encodeURIComponent(
    client_id,
  )}&client_secret=${encodeURIComponent(
    client_secret,
  )}&refresh_token=${encodeURIComponent(refresh_token)}&access_type=offline`;
  const [refresh_request] = useState({
    body: post_body,
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
  const [access_token, setAccess_token] = useState();

  useEffect(() => {

    try {
      fetch(refresh_url, refresh_request)
        .then((response) => {
          return response.json();
        })
        .then((response_json) => {
          if (response_json) {
            setAccess_token(response_json.access_token);
            console.log('access_token', true);
          }
        });
    } catch (error) {
      console.log('error', error);
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalShowApply, setModalShowApply] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [validated, setValidated] = useState(false);

  const [emailError] = useState('Vui lòng điền Email');
  const [nameError] = useState('Vui lòng điền tên');
  const [phoneError] = useState('Vui lòng điền số điện thoại');
  const [birthdayError] = useState('Vui lòng điền ngày sinh');
  const [sexError] = useState('Vui lòng chọn giới tính');
  const [addressError] = useState('Vui lòng chọn nơi tuyển dụng');
  const [fileError] = useState('Vui lòng chọn file CV');

  const [dataRequest, setDataRequest] = useState({
    timestamp: new Date().toLocaleString(),
    email: '',
    surName: '',
    fullName: '',
    phoneNumber: '',
    birthday: '',
    linkCv: '',
    fileOther: '',
    linkPortfolio: ' ',
    workPlace: '',
    sex: '',
    careerJob: ''
  });

  async function UploadFile() {
    var fileCv = '';
    let file = document.getElementsByName('upFile')[0].files[0]; // file
    if (file) {
      var metadata = {
        name: file.name,
        mimeType: file.type,
        parents: ['1fXqKxFbdn8fuh38Btb8eYOO9yaOKd4X5'], // Please set folderId here.
      };
      var form = new FormData();
      form.append(
        'metadata',
        new Blob([JSON.stringify(metadata)], { type: 'application/json' }),
      );
      form.append('file', file);
      await fetch(
        'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
        {
          method: 'POST',
          headers: new Headers({ Authorization: 'Bearer ' + access_token }),
          body: form,
        },
      )
        .then((res) => {
          return res.json();
        })
        .then(function (val) {
          if (val) {
            fileCv = `https://drive.google.com/file/d/${val.id}/view`;
            return fileCv;
          }
        });
    }
    return fileCv;
  }

  async function UploadFileOther() {
    var fileOther = '';
    let file = document.getElementsByName('upFileOther')[0].files[0]; // file
    if (file) {
      var metadata = {
        name: file.name,
        mimeType: file.type,
        parents: ['1fXqKxFbdn8fuh38Btb8eYOO9yaOKd4X5'], // Please set folderId here.
      };
      var form = new FormData();
      form.append(
        'metadata',
        new Blob([JSON.stringify(metadata)], { type: 'application/json' }),
      );
      form.append('file', file);
      await fetch(
        'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
        {
          method: 'POST',
          headers: new Headers({ Authorization: 'Bearer ' + access_token }),
          body: form,
        },
      )
        .then((res) => {
          return res.json();
        })
        .then(function (val) {
          if (val) {
            fileOther = `https://drive.google.com/file/d/${val.id}/view`;
            console.log('fileOther', fileOther);
            return fileOther;
          }
        });
    }
    return fileOther;
  }
  const clearInput = (e) => {
    e.target.email.value = "";
    e.target.fullName.value = "";
    e.target.phoneNumber.value = "";
    e.target.birthday.value = "";
    e.target.portfolio.value = "";
    setDataRequest({
      timestamp: new Date().toLocaleString(),
      email: '',
      surName: '',
      fullName: '',
      phoneNumber: '',
      birthday: '',
      linkCv: '',
      fileOther: '',
      linkPortfolio: ' ',
      workPlace: '',
      sex: '',
      careerJob: ''
    })
  }

  const handelOnSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return null;
    }
    setIsLoading(true);
    setIsSuccess(true);
    var linkCV = await UploadFile();
    var fileOther = await UploadFileOther();
    dataRequest.timestamp = new Date().toLocaleString();
    dataRequest.email = e.target.email.value;
    dataRequest.fullName = e.target.fullName.value;
    dataRequest.phoneNumber = e.target.phoneNumber.value;
    dataRequest.birthday = e.target.birthday.value;
    dataRequest.linkCv = linkCV;
    dataRequest.fileOther = fileOther;
    dataRequest.linkPortfolio = e.target.portfolio.value;
    dataRequest.careerJob = props.jobSelected;
    if (Object.keys(dataRequest).length > 0) {
      axios
        .post(
          'https://sheet.best/api/sheets/9a09b920-7590-44d7-866e-17830307cd8a',
          dataRequest,
        )
        .then((response) => {
          if (response.status === 200) {
            setValidated(false);
            clearInput(e);
            linkCV = "";
            fileOther = "";
          }
        });
      e.preventDefault();
      e.stopPropagation();
    }
    setIsLoading(false);
    setModalShowApply(true);
    setTimeout(() => {
      setModalShowApply(false);
    }, 4000);

  }
  const [isSuccess, setIsSuccess] = useState(false);

  const handleValidatorNumber = (e) => {
    const val = e.target.value;
    const maxLength = 10;
    const newVal = val.length <= maxLength ? val : val.toString().substring(0, maxLength)
    e.target.value = newVal;
  }

  const handleUpLoadFile = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const typeAccept = ['doc', 'docx', 'pdf', 'xls', 'xlsx', 'ppt', 'pptx', 'vnd.openxmlformats-officedocument.wordprocessingml.document']
      const index = typeAccept.findIndex((type) => type === file.type.slice(12, file.type.length));
      if (index <= 0) {
        setIsShowDialogError(true)
        e.target.value = null;
      }
    }
  }


  const [maxYear] = useState(parseInt(new Date().getFullYear() - 14));
  const [isShowDialogError, setIsShowDialogError] = useState(false);

  const DialogError = () => {
    return <Modal show={isShowDialogError} onHide={() => setIsShowDialogError(false)} className='dialog-message'>
      <Modal.Header closeButton className='dialog-message-wrap'><div className="message-error">Sai định dạng</div></Modal.Header>
    </Modal>
  }

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg" className="form-apply" centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="show-grid" >
          <Container>
            <Form noValidate validated={validated} onSubmit={(e) => handelOnSubmit(e)}>
              <Row>
                <Col xs={3} md={3} className="modal-info">
                  Email cá nhân <span>*</span>
                </Col>
                <Col xs={9} md={9} className="modal-input-info">
                  <Form.Group>
                    <Form.Control
                      placeholder="nguyenvana@gmail.com"
                      maxLength='64'
                      name="email"
                      type="email"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {emailError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={3} md={3} className="modal-info">
                  Họ và Tên <span>*</span>
                </Col>
                <Col xs={9} md={9} className="modal-input-info">
                  <Form.Group>
                    <Form.Control
                      placeholder="Nguyen Van A"
                      maxLength='32'
                      name="fullName"
                      type="text"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {nameError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={3} md={3} className="modal-info">
                  Số điện thoại <span>*</span>
                </Col>
                <Col xs={9} md={9} className="modal-input-info">
                  <Form.Group>
                    <Form.Control
                      type="number"
                      placeholder="0333333333"
                      name="phoneNumber"
                      onInput={handleValidatorNumber}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {phoneError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={3} md={3} className="modal-info">
                  Ngày sinh <span>*</span>
                </Col>
                <Col xs={9} md={9} className="modal-input-info">
                  <Form.Group>
                    <Form.Control
                      placeholder="01/01/1998"
                      type="date"
                      name="birthday"
                      defaultValue="1998-01-01"
                      pattern="\d{4}-\d{2}-\d{2}"
                      min="1970-01-01" max={`${maxYear}-12-31`}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {birthdayError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={3} md={3} className="modal-info">
                  Giới tính <span>*</span>
                </Col>
                <Col xs={9} md={9} className="modal-input-info">
                  <Form.Group>
                    <Form.Control name='sex' required as="select" placeholder="Chọn giới tính" onChange={(e) => { dataRequest.sex = e.target.value; }} className="form-select">
                      <option className="is-placeholder" value="">Chọn giới tính</option>
                      <option className="option-selected" value="Nam">Nam</option>
                      <option className="option-selected" value="Nữ">Nữ</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {sexError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={3} md={3} className="modal-info">
                  Khu vực ứng tuyển <span>*</span>
                </Col>
                <Col xs={9} md={9} name='workPlace' className="modal-input-info">
                  <Form.Group>
                    <Form.Control required as="select" className="form-select" onChange={(e) => { dataRequest.workPlace = e.target.value }}>
                      <option value="">Nơi làm việc</option>
                      <option value="Hồ Chí Minh">Tp. Hồ Chí Minh</option>
                      <option value="Hà Nội">Hà Nội</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {addressError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={3} md={3} className="modal-info">
                  File CV <span>*</span>
                </Col>
                <Col xs={9} md={9} className="modal-input-file">
                  <Form.Group>
                    <Form.Control
                      type="file"
                      name="upFile"
                      required
                      accept=".doc,.docx,.xml,application/msword,application/pdf,.pdf"
                      onInput={handleUpLoadFile}
                    />
                    <span>
                      Định dạng file: doc, docx, ppt, pptx, pdf.
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {fileError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={3} md={3} className="modal-info">
                  Hồ sơ khác (Bảng điểm/ Cover Letter/ Chứng chỉ/ Bài Test){' '}
                </Col>
                <Col xs={9} md={9} className="modal-input-file">
                  <Form.Group>
                    <Form.Control
                      type="file"
                      name="upFileOther"
                      accept=".doc,.docx,.xml,,application/pdf,.pdf,application/msword"
                      onInput={handleUpLoadFile}
                    />
                    <span>
                      Định dạng file: doc, docx, xls, xlsx, ppt, pptx, pdf.
                    </span>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={3} md={3} className="modal-info">
                  Link portfolio
                </Col>
                <Col xs={9} md={9} className="modal-input-info">
                  <Form.Group>
                    <Form.Control
                      placeholder="Link portfolio..."
                      name="portfolio"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={3} md={3} className="modal-info"></Col>
                <Col xs={9} md={9} className="modal-btn-submit">
                  <Button variant="primary" type="submit" disabled={isSuccess}>
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      'ỨNG TUYỂN'
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
      <ModalApplySuccess
        show={modalShowApply}
        onHide={() => setModalShowApply(false)}
      />
      <DialogError />
    </>
  );
}
