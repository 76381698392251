import React, { useState, useEffect } from "react";
import { Link, graphql, navigate } from "gatsby";
import { Col, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, History } from "swiper";

import Layout from "../components/layout";
import ModalApply from "../components/modal-apply";
import FormInformation from "../components/form-information";


import iconArrowRight from "../images/icons/icon-arrow-right.png";
import iconText from "../images/icons/icon-text.png";

import { Helmet } from 'react-helmet';

import "swiper/css";
import "swiper/css/pagination";


SwiperCore.use([Pagination, History]);
const isBrowser = typeof window !== "undefined";

export default function JobPosition({ pageContext, location, data }) {

  const [isDesktop, setIsDesktop] = useState(false);
  const [countColumn, setCountColumn] = useState(5);
  const [titleCurrent, setTitleCurrent] = useState();
  const { dataJobDetail } = pageContext;
  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("resize", () => {
        if (window.innerWidth > 768) {
          setIsDesktop(true);
          setCountColumn(5)
        } else {
          setIsDesktop(false);
          setCountColumn(12)
        }
      }, false);
      if (window.innerWidth > 768) {
        setIsDesktop(true);
        setCountColumn(5)
      } else {
        setIsDesktop(false);
        setCountColumn(12)
      }

    }
    return () => {
      if (isBrowser) {
        window.removeEventListener('resize', () => {
          if (window.innerWidth > 768) {
            setIsDesktop(true);
            setCountColumn(5)
          } else {
            setIsDesktop(false);
            setCountColumn(12)
          }
        }, false)
      }
    }
  }, [])

  const [dataSearch] = useState(data.allWpPost.edges);
  const [modalShow, setModalShow] = useState(false);
  const [arrCareer] = useState(pageContext.arrayJob);

  const [arrJobHCNS] = useState(pageContext.listCareer || []);

  const [arrJobKTTC] = useState(pageContext.listCareer || []);

  const [arrJobNCPT] = useState(pageContext.listCareer || []);

  const [arrJobSXVH] = useState(pageContext.listCareer || []);
  const [arrDetailCareer, setArrDetailCareer] = useState([]);
  const search = new URLSearchParams(location.search);
  const dateNow = new Date();
  const getDataBycareerid = (careerid) => {
    setArrDetailCareer([]);
    var arrDetailCareerNew = [];
    if(careerid ===5){
      var newList = dataSearch.filter(career => dateNow<formatDate(career.node.modelCareer.date));
      
      const keySearch = {
        keyWord: search.get("keyWord"),
        job: search.get("job"),
        address: search.get("address"),
      }
      newList = filterDataSearch(keySearch, newList);
      setArrDetailCareer(newList);
    } else {
      switch (careerid) {
        case 1:
          arrDetailCareerNew = arrJobNCPT;
          break;
        case 2:
          arrDetailCareerNew = arrJobSXVH;
          break;
        case 3:
          arrDetailCareerNew = arrJobHCNS;
          break;
        case 4:
          arrDetailCareerNew = arrJobKTTC;
          break;
        default:
        break;
      }
      var arrNew = [];
      if (arrDetailCareerNew?.length > 0) {
        arrNew = arrDetailCareerNew.filter(career => dateNow<formatDate(career.node.modelCareer.date));
      }
      setArrDetailCareer(arrNew);
    }
    
  };

  const formatDate = (dateFormat) => {
    const dateItem = new Date(dateFormat);
    return dateItem
  }

  useEffect(() => {
    if (document.getElementById('job-page-id')) {
      document.body.style.height = '100vh';
      //  document.querySelector('html').style.overflowY = "hidden";
      document.querySelector('body').style.overflowY = "auto";
    }
    return () => {
      document.querySelector('html').style.overflowY = "auto";
      document.querySelector('body').style.overflowY = "auto";
      document.body.style.height = 'unset';
    }
  })

  const filterDataSearch = (keySearch, newList) => {
    const jobIndex = parseInt(keySearch.job);
    if (jobIndex !== 0) {
      newList = newList.filter((detail) => jobIndex === parseInt(detail.node.modelCareer.careerid));
    }
    if (keySearch.address !== '' && keySearch.address !== 'Nơi làm việc') {
      newList = newList.filter((detail) => keySearch.address === detail.node.modelCareer.address);
    }
    if (keySearch.keyWord.length > 0) {
      newList = newList.filter((detail) => detail.node.modelCareer.title.toLowerCase().indexOf(keySearch.keyWord.toLowerCase()) > -1);
    }
    return newList;
  }


  const handleCallbackSearch = (keySearch) => {
    var newList = dataSearch.filter(career => dateNow<formatDate(career.node.modelCareer.date));
    newList = filterDataSearch(keySearch, newList);
    setArrDetailCareer(newList);
    setTitleCurrent("Job Search");
  };

  

  useEffect(() => {
    const getDateInWordPress= ()=> {
      for (const career of arrCareer) {
        if (location.pathname.indexOf(career.node.frontmatter.slug) > -1) {
          getDataBycareerid(career.node.frontmatter.careerid);
          setTitleCurrent(career.node.frontmatter.title);
          break;
        }
        if (location.pathname.indexOf('/vi-tri-ung-tuyen/tim-kiem') > -1) {
          getDataBycareerid(5);
          setTitleCurrent("Tìm Kiếm job");
          break;
        }
      }
    }
    getDateInWordPress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Pagination
  const changePagination = (swiper) => {
    if (swiper && swiper.activeIndex % 6 !== 0) {
      const indexTransform =
        swiper.snapGrid[swiper.snapGrid.length - 2] + swiper.width;
      document.getElementsByClassName(
        "swiper-wrapper"
      )[0].style.transform = `translate3d(-${indexTransform}px, 0px, 0px)`;
    }
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  const [jobSelected, setJobSelected] = useState();
  const handleOpenModal = (jobSelected) => {
    setModalShow(true);
    setJobSelected(jobSelected);
  }

  const CareerList = () => {
    return (
      <Col xl={countColumn} lg={countColumn} md={countColumn} className="work-list">
        <div className="work-list-title">
          <h4>Vị trí đang tuyển</h4> {pageContext?.html && pageContext?.html?.length > 0 && <div className="back-list-job" aria-label="back" onClick={() => navigate(-1)} onKeyDown={() => navigate(-1)} role="button" tabIndex={0}></div>}
        </div>
        {!pageContext?.html && arrDetailCareer?.length <= 0 && (
          <div className="no_result">
            <span>Dữ liệu đang được cập nhật</span>
          </div>
        )}
        {!pageContext?.html && (
          <Swiper
            history={{
              "key": "slide"
            }}
            slidesPerView={6}
            slidesPerGroup={6}
            onSlideChange={(swiper) => changePagination(swiper)}
            pagination={pagination}
            className="mySwiper"
          >
            {arrDetailCareer &&
              arrDetailCareer.map((item, index) => {
                return (
                  item.node.html !== "" && (
                    <SwiperSlide key={index} data-history={index === 0 ? 1 : Math.round((index / 6)) + 1}>
                      <div className="work-list-item">
                        <div className="work-date">
                          <span>{item?.node?.modelCareer?.date}</span>
                        </div>
                        <Link
                          to={`/vi-tri-ung-tuyen/${item?.node?.modelCareer?.slug}`}
                        >
                          <div className="work-name">
                            <span>{item?.node?.modelCareer?.title}</span>
                          </div>
                        </Link>

                        <div className="work-desc">
                          <p>
                            {item?.node?.modelCareer?.subtitle}
                          </p>
                          <p> Nơi làm viêc: {item?.node?.modelCareer?.address}</p>
                        </div>
                        <div className="work-apply">
                          <span
                            onClick={() => handleOpenModal(item?.node?.modelCareer?.title)}
                            onKeyDown={() => handleOpenModal(item?.node?.modelCareer?.title)}
                            role="button"
                            tabIndex={0}
                          >
                            Ứng tuyển
                          </span>
                          {/* <img src={line} alt="" className="work-apply-line" /> */}
                        </div>
                      </div>
                      {/* <hr /> */}
                    </SwiperSlide>
                  )
                );
              })}
          </Swiper>
        )
        }

        {
          pageContext?.html && pageContext?.html?.length > 0 && (
            <div className="work-detail">
              <div dangerouslySetInnerHTML={{ __html: pageContext.html }} />
              <div className="work-detail-btn">
                <button className="btn-apply" onClick={() => handleOpenModal(dataJobDetail.title)}>
                  <span>ỨNG TUYỂN</span>{" "}
                </button>
                {/* <button className="btn-intro">
                <span>GIỚI THIỆU ỨNG VIÊN</span>{' '}
              </button> */}
              </div>
            </div>
          )
        }
      </Col >
    );
  };

  const activeStyle = {
    color: '#ff8c00'
  }
  const CareerType = () => {
    return <>
      <div className="job-list-title">
        <h4>Các ngành nghề</h4>
      </div>
      <ul>
        {arrCareer &&
          arrCareer.map((career, index) => (
            <li key={index}>
              <img src={iconArrowRight} alt="" />
              <Link activeStyle={activeStyle}
                partiallyActive={true}
                to={`/vi-tri-ung-tuyen/${career.node.frontmatter.slug}`}
                onClick={() => setTitleCurrent(career.node.frontmatter.title)}
              >
                {career.node.frontmatter.title}
              </Link>
            </li>
          ))}
      </ul>
    </>
  };


  return (
    <>
      <Helmet>
        <title>{dataJobDetail?.title || titleCurrent || 'Tuyển dụng Unicloud'} </title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" content="Career Unicloud. Tuyển dụng Unicloud." />
        <meta property="og:title" content="Career Unicloud. Tuyển dụng Unicloud" />
        <meta property="og:description" content="Career Unicloud. Tuyển dụng công việc. Cơ hội nghề nghiệp dành cho bạn tại Unicloud. Tìm hiểu ngay tại đây!" />
        <meta property="og:url" content="https://career.unicloud.com.vn/" />
        <meta property="og:images" content="/favicon-32x32.png" />
        <meta property="og:site_name" content="Tuyển dụng Unicloud" />
        <meta property="article:author" content="https://www.facebook.com/UnicloudCareers" />
        <meta property="article:tag" content="Tuyển dụng Unicloud" />
        <meta property="article:tag" content="career Unicloud" />
        <meta property="article:section" content="Tuyển dụng" />
        <meta property="article:images" content="/favicon-32x32.png" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Layout>
        <article>
          <div className="job-banner">
            <div className="banner-icon">
              <img src={iconText} alt="banner tuyển dụng unicloud" loading="lazy" />
            </div>
            <div className="banner-content">
              <p>
                Môi trường thúc đẩy sự sáng tạo và luôn chào đón những ai dám
                đón nhận thách thức để theo đuổi đam mê...
              </p>
            </div>
          </div>
        </article>
        <article>
          <div className="job-page" id="job-page-id">
            <div className="container-wrap">
              {!isDesktop && (<FormInformation parentCallback={handleCallbackSearch} location={location} />)}
              {isDesktop && (<Row className="breadcrumb-page">
                <Col>
                  <div className="breadcrumb-default link-hp">
                    <span>
                      <Link to="/">Trang chủ</Link>
                    </span>
                  </div>
                  <div className="breadcrumb-icon">
                    <img src={iconArrowRight} alt="" />
                  </div>
                  <div className="breadcrumb-default">
                    <span>Vị trí ứng tuyển</span>
                  </div>
                  <div className="breadcrumb-icon">
                    <img src={iconArrowRight} alt="" />
                  </div>
                  <div className="breadcrumb-present">
                    {pageContext.arrayJob.map((job, index) => {
                      return <span key={index}>{location.pathname.indexOf(job.node.frontmatter.slug) > -1 ? job.node.frontmatter.title : null}</span>
                    })}

                  </div>
                  <div className="breadcrumb-present">
                    <span>{location.search && (<span>Tìm kiếm</span>)}</span>
                  </div>
                </Col>
              </Row>)}
              <Row className="job-body">
                {isDesktop && (<Col xl={3} lg={3} md={3} className="job-list">
                  <CareerType />
                </Col>)}
                <CareerList />
                {isDesktop && (<Col xl={4} lg={4} md={4}>
                  <FormInformation parentCallback={handleCallbackSearch} location={location} />
                </Col>)}
              </Row>
            </div>
          </div>
        </article>
        <ModalApply
          show={modalShow}
          onHide={() => setModalShow(false)}
          jobSelected={jobSelected}
        />
      </Layout>
    </>
  );
}

export const pageQuery = graphql`
{
  allWpPost {
    edges {
      node {
        content
        date
        modelCareer {
          address
          careerid
          date
          prioritized
          istitle
          slug
          subtitle
          title
        }
      }
    }
  }
}
`;
